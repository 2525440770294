
$logo: "/assets/images/logo.png";

@import "./support/support";
@import "./custom/setup";
@import "./color_schemes/light";

@import "./color_schemes/darkskhep";

@import "./modules";
div.opaque {
  background-color: $body-background-color;
}p.warning, blockquote.warning {
    background: rgba($red-000, 0.2);
    border-left: $border-radius solid $red-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    &::before {
        color: $red-300;
        content: "Warning";
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        font-size: .75em;
        padding-bottom: .125rem;
    }
    
    > .warning-title {
      color: $red-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.warning-title, blockquote.warning-title {
    background: rgba($red-000, 0.2);
    border-left: $border-radius solid $red-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $red-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.warning {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.warning-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

p.note, blockquote.note {
    background: rgba($yellow-000, 0.2);
    border-left: $border-radius solid $yellow-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    &::before {
        color: $yellow-300;
        content: "Note";
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        font-size: .75em;
        padding-bottom: .125rem;
    }
    
    > .note-title {
      color: $yellow-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.note-title, blockquote.note-title {
    background: rgba($yellow-000, 0.2);
    border-left: $border-radius solid $yellow-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $yellow-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.note {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.note-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

p.important, blockquote.important {
    background: rgba($green-000, 0.2);
    border-left: $border-radius solid $green-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    &::before {
        color: $green-300;
        content: "Important";
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        font-size: .75em;
        padding-bottom: .125rem;
    }
    
    > .important-title {
      color: $green-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.important-title, blockquote.important-title {
    background: rgba($green-000, 0.2);
    border-left: $border-radius solid $green-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $green-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.important {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.important-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

p.highlight, blockquote.highlight {
    background: rgba($blue-000, 0.2);
    border-left: $border-radius solid $blue-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    > .highlight-title {
      color: $blue-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.highlight-title, blockquote.highlight-title {
    background: rgba($blue-000, 0.2);
    border-left: $border-radius solid $blue-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $blue-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.highlight {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.highlight-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}


@import "./custom/custom";


